*, html{
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
}

@font-face {
  font-family: "Jaldi-Regular";
  src:
    local("Jaldi-Regular"),
    url("./fonts/Jaldi/Jaldi-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Jaldi-Bold";
  src:
    local("Jaldi-Bold"),
    url("./fonts/Jaldi/Jaldi-Bold.ttf") format("truetype");
  font-weight: bold;
}

@keyframes apparition {
  0% {
    top: 100px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

/* Mobile */

#root{
  overflow: hidden;
  font-family: "Jaldi-Regular";
}

#root .banner{
  height: 100px;
  width: 100vw;
  object-fit: cover;
  opacity: 0.5;
  position: absolute;
}

#root .accroche{
  padding: 73px 25px 29px 25px;
  position: relative;
  z-index: 2;
  opacity: 0;
  animation: apparition 0.5s ease-in-out forwards;
}

#root .accroche .logo{
  width: 155px;
  height: auto;
}

#root .accroche .contact-button{
  border: none;
  border-radius: 8px;
  padding: 9px 12px;
  background-color: #E5C6FE;
  float: right;
  margin-top: 16px;
  text-decoration: none;
  color: black;
}

#root .accroche .contact-button img{
  margin-right: 9px;
}

#root .accroche h1{
  font-family: "Jaldi-Bold";
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 27px 0 9px 0;
}

#root .accroche p{
  font-family: "Jaldi-Regular";
  font-size: 12px;
  line-height: 1.2;
  margin: 0;
}

#root .video1{
  padding: 0 25px 29px 25px;
  overflow: hidden;
  width: 100vw;
  box-sizing: border-box;
  height: 500px;
  opacity: 0;
  animation: apparition 0.5s ease-in-out 0.5s forwards;
}

#root .video1 div{
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

#root .video1 div video{
  width: 100%;
  border-radius: 8px;
}

#root .services{
  padding: 0 25px 29px 25px;
  position: relative;
  z-index: 2;
  opacity: 0;
  animation: apparition 0.5s ease-in-out 0.7s forwards;
}

#root .services h2{
  font-family: "Jaldi-Bold";
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 11px 0;
}

#root .service-entretien{
  border-radius: 8px;
  overflow: hidden;
  background-color: #E5C6FE;
}

#root .service-entretien .top{
  height: 153px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

#root .service-entretien .top h3{
  font-family: "Jaldi-Bold";
  font-size: 40px;
  color: #EBF3EE;
  text-transform: uppercase;
  margin: 0 0 0 25px;
}

#root .service-entretien .bottom{
  padding: 25px;
}

#root .service-entretien .bottom p{
  margin: 0;
  font-size: 14px;
}

#root .service-entretien .bottom ul{
  margin: 0;
  padding-left: 20px;
}

#root .service-entretien .bottom ul li{
  font-size: 14px;
}

#root .service-elagage{
  border-radius: 8px;
  overflow: hidden;
  background-color: #E5C6FE;
  margin-top: 25px;
}

#root .service-elagage .top{
  height: 153px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#root .service-elagage .top h3{
  font-family: "Jaldi-Bold";
  font-size: 40px;
  color: #EBF3EE;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 25px;
}

#root .service-elagage .bottom{
  padding: 25px;
}

#root .service-elagage .bottom p{
  margin: 0;
  font-size: 14px;
}

#root .service-elagage .bottom ul{
  margin: 0;
  padding-left: 20px;
}

#root .service-elagage .bottom ul li{
  font-size: 14px;
}

#root .service-creation{
  border-radius: 8px;
  overflow: hidden;
  background-color: #E5C6FE;
  margin-top: 25px;
}

#root .service-creation .top{
  height: 153px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#root .service-creation .top h3{
  font-family: "Jaldi-Bold";
  font-size: 40px;
  color: #EBF3EE;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 25px;
}

#root .service-creation .bottom{
  padding: 25px;
}

#root .service-creation .bottom p{
  margin: 0;
  font-size: 14px;
}

#root .service-creation .bottom ul{
  margin: 0;
  padding-left: 20px;
}

#root .service-creation .bottom ul li{
  font-size: 14px;
}

#root .video2{
  padding: 0 25px 29px 25px;
  overflow: hidden;
  width: 100vw;
  box-sizing: border-box;
  height: 500px;
}

#root .video2 div{
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

#root .video2 div video{
  width: 100%;
  border-radius: 8px;
}

#root .bandeau{
  background-color: #E5C6FE;
  padding: 18px 25px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

#root .bandeau .warning-icon{
  height: 26px;
  width: 28px;
  grid-column: 1;
  align-self: center;
}

#root .bandeau p{
  font-family: "Jaldi-Bold";
  font-size: 12px;
  margin: 0;
  grid-column: 3 / 10;
}

#root .rayon{
  margin: 26px 25px;
}

#root .rayon h2{
  font-family: "Jaldi-Bold";
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}

#root .rayon p{
  font-size: 12px;
  margin: 0;
}

#root .rayon .map-box{
  width: 100%;
  box-sizing: border-box;
  margin-top: 11px;
  border-radius: 8px;
  overflow: hidden;
  height: 250px;
}

#root .rayon .map-box img{
  width: 100%;
  box-sizing: border-box;
}

#root .contact{
  padding: 20px 25px;
  background-color: #E5C6FE;
}

#root .contact h2{
  font-family: "Jaldi-Bold";
  font-size: 16px;
  margin: 0 0 11px 0;
  text-transform: uppercase;
}

#root .contact form input{
  display: block;
  padding: 7px 8px 8px 8px;
  box-sizing: border-box;
  border: 0;
  height: 32px;
  width: 60%;
  border-radius: 8px;
  margin-bottom: 8px;
}

#root .contact form textarea{
  display: block;
  padding: 7px 8px 8px 8px;
  box-sizing: border-box;
  border: 0;
  height: 115px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 9px;
  resize: none;
}

#root .contact form button{
  border: 0;
  background-color: white;
  border-radius: 8px;
  height: 26px;
  padding: 4px 8px 5px 8px;
  box-sizing: border-box;
  display: flex;
  float: right;
}

#root .contact form button img{
  margin-right: 6px;
}

#root .contact form button span{
  font-size: 15px;
  color: black;
}

#root .contact p{
  font-size: 14px;
  margin: 9px 0 0 0;
}

#root .contact span{
  display: block;
  font-size: 14px;
}

#root .contact a{
  display: block;
  color: black;
  font-size: 14px;
}

#root .footer{
  background-color: #EBF3EE;
  padding: 26px 25px 33px 25px;
  width: 100%;
  box-sizing: border-box;
}

#root .footer h3{
  font-size: 16px;
  text-transform: uppercase;
}

#root .footer a{
  display: block;
  color: black;
  text-transform: uppercase;
}

/* Desktop */
@media screen and (min-width: 577px){
  #root .banner{
    height: auto;
    width: 100vw;
  }

  #root .accroche{
    padding: 217px 240px 53px 240px;
    position: relative;
    z-index: 2;
    opacity:0;
    animation: apparition 0.5s ease-in-out forwards;
  }
  
  #root .accroche .logo{
    height: 120px;
    width: auto;
  }

  #root .accroche .contact-button{
    padding: 18px;
    font-size: 22px;
    display: flex;
    align-items: center;
    margin-top: 38px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    text-decoration: none;
    color: black;
  }

  #root .accroche .contact-button:hover{
    transform: scale(1.1);
  }

  #root .accroche .contact-button .letter-icon{
    height: 21px;
    width: 28px;
  }

  #root .accroche h1{
    margin: 70px 0 24px 0;
    font-size: 35px;
  }

  #root .accroche p{
    font-size: 18px;
    width: 55%;
  }

  #root .videos{
    height: 47vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 37px;
    padding: 0 240px 90px 240px;
  }

  #root .videos .left, #root .videos .right{
    overflow: hidden;
    border-radius: 8px;
  }

  #root .videos .left{
    opacity: 0;
    animation: apparition 0.5s ease-in-out 0.5s forwards;
  }

  #root .videos .right{
    opacity: 0;
    animation: apparition 0.5s ease-in-out 0.7s forwards;
  }

  #root .videos .left video, #root .videos .right video{
    width: 100%;
  }

  #root .services{
    padding: 0 240px 90px 240px;
    opacity: 0;
    animation: apparition 0.5s ease-in-out 0.9s forwards;
  }

  #root .services > h2{
    font-size: 28px;
  }

  #root .services .service-entretien{
    background: transparent;
    border-radius: 0;
    display: flex;
    align-items: center;
  }

  #root .services .service-entretien .blob-box{
    min-height: 434px;
    min-width: 426px;
    background-image: url("assets/entretien.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-mask-image: url("assets/blob.svg");
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  #root .services .service-entretien .text-box h3{
    font-size: 40px;
    margin: 0 0 17px 0;
    font-family: "Jaldi-Bold";
  }

  #root .services .service-entretien .text-box p{
    font-size: 18px;
    margin: 0;
  }

  #root .services .service-entretien .text-box ul{
    font-size: 18px;
    margin-top: 0;
  }

  #root .services .service-elagage{
    background: transparent;
    border-radius: 0;
    display: flex;
    align-items: center;
  }

  #root .services .service-elagage .blob-box{
    min-height: 434px;
    min-width: 426px;
    background-image: url("assets/elagage.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-mask-image: url("assets/blob.svg");
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  #root .services .service-elagage .text-box h3{
    font-size: 40px;
    margin: 0 0 17px 0;
    font-family: "Jaldi-Bold";
  }

  #root .services .service-elagage .text-box p{
    font-size: 18px;
    margin: 0;
  }

  #root .services .service-elagage .text-box ul{
    font-size: 18px;
    margin-top: 0;
  }

  #root .services .service-creation{
    background: transparent;
    border-radius: 0;
    display: flex;
    align-items: center;
  }

  #root .services .service-creation .blob-box{
    min-height: 434px;
    min-width: 426px;
    background-image: url("assets/creation.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-mask-image: url("assets/blob.svg");
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  #root .services .service-creation .text-box h3{
    font-size: 40px;
    margin: 0 0 17px 0;
    font-family: "Jaldi-Bold";
  }

  #root .services .service-creation .text-box p{
    font-size: 18px;
    margin: 0;
  }

  #root .services .service-creation .text-box ul{
    font-size: 18px;
    margin-top: 0;
  }

  #root .bandeau{
    padding: 60px 300px;
  }

  #root .bandeau .warning-icon{
    width: 57px;
    height: 54px;
  }

  #root .bandeau p{
    font-size: 28px;
    line-height: 1.2;
    grid-column: 2/10;
  }

  #root .rayon{
    padding: 84px 240px;
    display: flex;
    align-items: center;
    gap: 37px;
  }

  #root .rayon .left-box{
    width: 50%;
    display: inline-block;
  }

  #root .rayon .left-box h2{
    font-size: 35px;
  }

  #root .rayon .left-box p{
    font-size: 24px;
    line-height: 1.2;
  }

  #root .rayon .map-box{
    width: 50%;
    display: inline-block;
    height: 350px;
  }

  #root .contact{
    padding: 60px 240px;
  }

  #root .contact h2{
    font-size: 28px;
  }

  #root .contact form{
    display: grid;
    grid-template-columns: 2;
    column-gap: 37px;
    row-gap: 22px;
  }

  #root .contact form input{
    height: 45px;
    width: 60%;
    grid-column: 1/2;
    margin-bottom: 0;
  }

  #root .contact form input.email, #root .contact form input.location{
    width: 100%;
    margin-bottom: 0;
  }

  #root .contact form textarea{
    height: 179px;
    float: right;
    vertical-align: bottom;
    grid-column: 2/3;
    grid-row: 2/5;
    margin-bottom: 0;
  }

  #root .contact .hidden{
    display: none;
  }

  #root .contact label{
    border: 0;
    background-color: white;
    border-radius: 8px;
    height: 35px;
    padding: 4px 8px 5px 8px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    float: right;
    width: 111px;
    justify-content: space-around;
    margin-top: 22px;
    cursor: pointer;
    transition: all .1s ease-in-out;
  }

  #root .contact label:hover{
    transform: scale(1.1);
  }
  
  #root .contact label img{
    margin-right: 6px;
  }
  
  #root .contact label span{
    font-size: 15px;
    color: black;
  }

  #root .contact p{
    margin-top: 35px;
  }

  #root .contact p, #root .contact span, #root .contact a{
    font-size: 22px;
  }

  #root .footer{
    padding: 60px 240px;
  }

  #root .footer h3{
    font-size: 22px;
  }

  #root .footer a{
    font-size: 18px;
  }
}